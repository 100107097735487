import ClubStartpage from './ClubStartpage';
// import About from './About';
import Schedule from './Schedule';
import Contact from './Contact';
import Prices from './Prices';
import UrsvikSummerSchedule from './UrsvikSummerSchedule';
import Rules from './Rules';

interface Activity {
    day: string,
    time: string,
    type: string,
    room: string,
    grade: string,
    key: number
}
interface ClubContact {
    club: string,
    mail: string,
    tel: string,
    altTel: string,
    address: string
}
interface Props {
    contactInfo: ClubContact[],
}
interface IPrice {
    article: string,
    price: string
}
const Ursvik = (props: Props) => {
    const days: string[] = ["Tis", "Tors"];
    const activities: Activity[] = [
        {day: "Tis", time: "18.00-19.00", type: "Thaiboxning", room: "Blå rummet", grade: "Barn 10-14", key: 1},
        {day: "Tis", time: "19.15-20.30", type: "Thaiboxning", room: "Blå rummet", grade: "Motion", key: 2},
        {day: "Tors", time: "18.00-19.00", type: "Thaiboxning", room: "Blå rummet", grade: "Barn 10-14", key: 3},
        {day: "Tors", time: "19.15-20.30", type: "Thaiboxning", room: "Blå rummet", grade: "Motion Vuxen Ungdom", key: 4},
        // {day: "Sön", time: "12.30", type: "MMA",  room: "Röda rummet",grade: "Motion", key: 5},
        // {day: "Sön", time: "12.30-14.00", type: "MMA**",  room: "Röda rummet",grade: "Vuxna", key: 6},
    ];
    const prices: IPrice[] = [
        {article: "Medlemsavgift", price: "100"},
        {article: "Terminsavgift", price: "1900"},
        {article: "Terminsavgift Solna + Ursvik", price: "3000"},
        {article: "Terminsavgift barn", price: "1700"},
        {article: "Terminsavgift Solna + Ursvik barn", price: "2200"},
        {article: "Månadsträning", price: "700"},
        {article: "Träning 1 gång", price: "150"},
        {article: "Klippkort 10ggr", price: "1200"},
        {article: "Grupp svensexa/kickoff", price: "1500"},
        {article: "Privatlektion 1 person", price: "500"},
        {article: "Privatlektion 2 personer", price: "700"},
        {article: "Privatlektion 3-10 personer", price: "1000"},
    ];
    return (
        <div>
            <ClubStartpage club="ursvik"/>
            {/* <About/> */}
            <Schedule days={days} activities={activities} ursvik={true}/>
            {/* <UrsvikSummerSchedule/> */}
            <Rules/>
            <Prices prices={prices} ursvik={true}/>
            <Contact contactInfo={props.contactInfo} club="ursvik"/>
        </div>
    );
};

export default Ursvik;