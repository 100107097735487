interface IPrice {
    article: string,
    price: string,
}

const Personal = () => {

    const prices: IPrice[] = [
        { article: "Ett pass", price: "700" },
        { article: "Klippkort 5 tillfällen", price: "3250" },
        { article: "Klippkort 10 tillfällen", price: "6000" },
        { article: "DUO Pass", price: "1000" },
        { article: "DUO Klippkort 5 tillfällen", price: "4500" },
        { article: "DUO Klippkort 10 tillfällen", price: "8500" },
        { article: "Företag eller förening. Kontakta oss via mail för offert.", price: ""}
      ];
      
    return (
        <section className="personal-wrapper" id="personal">
            <h2 className="private-heading">PERSONLIG TRÄNING</h2>
            <p className="personal-text">Vill du komma i form eller ta din träning till nästa nivå? Träna med våra erfarna coacher som har flerårig erfarenhet av att utveckla svenska mästare, landslagsmän och proffs. Med personlig träning får du skräddarsydda pass som hjälper dig att nå dina mål snabbare och effektivare. Oavsett om du är nybörjare eller erfaren inom MMA, så kommer vi hjälpa dig att prestera på topp!</p>
            <ul className="prices-ul">
            {prices.map((price: IPrice) => {
                    return (
                        <li key={price.article} className="price-listing">{price.article}{price.price ? `: ${price.price} SEK` : ""}</li>
                    )
                })}
            </ul>
        </section>
    );
};

export default Personal;