const Rules = () => {
    return (
        <section className="rules-wrapper" id="rules">
                <h2 className="rules-heading">INFÖR TRÄNINGEN</h2>
                <p>För att skapa en trygg och trivsam gemenskap har vi några enkla regler för att säkerställa din
                säkerhet och välbefinnande. Ta en stund att bekanta dig med informationen nedan, och tveka
                inte att höra av dig om du har några frågor.</p>
                <div className="rules-section">
                    <h3>Trivselregler</h3>
                    <ul>
                        <li>Kom i tid till träningspassen</li>
                        <li>Lyssna uppmärksamt på tränaren</li>
                        <li>Rena kläder och klippta naglar är obligatoriskt</li>
                        <li>Undvik starka parfymer för att respektera allergiker</li>
                        <li>Visa hänsyn och respekt gentemot alla i lokalen</li>
                        <li>Spara allt småprat till efter träningen</li>
                        <li>Inga smycken är tillåtna under träningen</li>
                    </ul>
                </div>
                <div className="rules-section">
                    <h3>Utrustning</h3>
                    <ul>
                        <li>Handskar och benskydd finns att låna vid provträningar. Därefter ska man ha skaffat
                        sig egen utrustning. Är ni osäkra på storlek och typ av utrustning så fråga gärna någon
                        av oss tränare.</li>
                        <li>Rekommenderad utrustning: Boxningshandskar, MMA-handskar, Suspensoar,
                        tandskydd, vaselin och boxningshjälm.</li>
                    </ul>
                </div>
                <div className="rules-section">
                    <h3>Betalning</h3>
                    <p>Betalning sker antingen via Swish eller kontant och terminsavgiften är bindande.</p>
                </div>
                <p className="rules-footer">Tack för att du valt oss - vi ser fram emot att träna tillsammans.</p>
        </section>
    )
}

export default Rules;